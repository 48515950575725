<script lang="ts" setup>
  import { h, normalizeStyle, type Slot } from 'vue';

  interface IProps {
    gap?: string;
    width?: string;
    height?: string;
    color?: string;
    br?: string;
    styles?: object;
    content?: string;
  }
  const props = withDefaults(defineProps<IProps>(), {
    gap: '16px',
    width: '1px',
    height: '16px',
    color: '#fff',
    br: '0',
  });

  const slots = defineSlots<{ default: Slot }>();

  const getVNodes = (slot: any) => {
    let vnodes: VNode[] = [];

    if (slot instanceof Array) {
      slot.forEach(childNode => {
        if (childNode.type.toString() === Symbol('v-fgt').toString()) {
          vnodes.push(...childNode.children);
        } else if (childNode.type.toString() !== Symbol('v-cmt').toString()) {
          vnodes.push(childNode);
        }
      });
    }
    return vnodes;
  };

  const renderNodes = () => {
    const vnodesInDefaultSlot = slots.default ? slots.default() : [];
    const vnodes = getVNodes(vnodesInDefaultSlot);
    const vnodesToRender: VNode[] = [];

    vnodes.forEach((el, index) => {
      vnodesToRender.push(h(el.type as string, [el.children as string]));
      if (index < vnodes.length - 1) {
        vnodesToRender.push(
          h(
            'div',
            {
              class: 'movie-info-group__divider',
              style: normalizeStyle({
                width: props.width,
                height: props.height,
                backgroundColor: props.color,
                borderRadius: props.br,
                ...props.styles,
              }),
            },
            [props.content],
          ),
        );
      }
    });
    return vnodesToRender;
  };

  const VNode = () => h('div', { style: { gap: props.gap }, class: 'movie-info-group' }, renderNodes());
</script>

<template>
  <v-node />
</template>

<style lang="scss">
  .movie-info-group {
    display: flex;
    color: $main_white;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    align-items: center;
    &__divider {
      opacity: 0.5;
    }
    span {
      white-space: nowrap;
    }
    @media (max-width: $retina) {
      font-size: 16px;
      line-height: 24px;
      flex-wrap: wrap;
    }
  }
</style>
